





















import { Component, Prop, Vue } from 'vue-property-decorator';

import ContainerSidebar from '@/components/ContainerSidebar/ContainerSidebar.vue';
import Book from '@/components/Book/index.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BookSidebarLoading from '@/components/BookSidebarLoading/index.vue';

import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';

@Component({
  components: {
    ContainerSidebar,
    Book,
    ContainerFluid,
    BookSidebarLoading,
    RealtimeUsers
  }
})
export default class ExamSidebar extends Vue {
  @Prop() cover!: string;
  @Prop() colorBook!: string;
  @Prop() title!: string;

  get showLoading() {
    return this.colorBook || this.cover || this.title;
  }
}
