




































































import { Component, Prop, Vue } from 'vue-property-decorator';

import Accordion from '@/components/Accordion/index.vue';

import ListFiles from '../ListFiles/index.vue';

import HistoricService from '@/services/Historic/historicService';

import { TypeMaterial } from '@/services/Historic/historicInterface';

@Component({
  name: 'ExamsTree',
  components: {
    Accordion,
    ListFiles
  }
})
export default class AccordionExam extends Vue {
  @Prop({ default: false }) hasPermission!: boolean;
  @Prop() padding!: string;
  @Prop() listTopic!: Record<string, any>;

  private topics: Record<string, any> = {};

  private ids: Array<number> = [];
  private allChecked: Array<Record<string, any>> = [];

  private historicService = new HistoricService();

  async mounted() {
    this.getIds(this.listTopic);
    await this.setAllChecked();
    this.topics = this.sortListTopic(this.listTopic);
  }

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  getIds(listTopic: Record<string, any>) {
    if (!listTopic.questions || listTopic.questions <= 0) {
      if (listTopic.files && listTopic.files.length) {
        listTopic.files.forEach((file: Record<string, any>) => {
          this.ids.push(file?.fileID);
        });
      }
    } else {
      listTopic.questions.forEach((question: Record<string, any>) => {
        this.getIds(question);
      });
    }
  }

  async setAllChecked() {
    if (this.ids.length > 99) {
      const response = await this.getHistoric('vestibular', this.ids.splice(0, 100));

      this.allChecked.push(...response);
      await this.setAllChecked();
    } else {
      const response = await this.getHistoric('vestibular', this.ids);

      this.allChecked.push(...response);
    }
  }

  async getHistoric(type: TypeMaterial, listIds: Array<number>) {
    try {
      if (!listIds.length) return [];

      const response = await this.historicService.getHistoric({ type, listIds });

      return response || [];
    } catch (error) {
      return [];
    }
  }

  async setCheckbox(name: string, checked: boolean, index: number) {
    await this.historicService.saveHistoric({
      idobject: index,
      type: 'vestibular',
      checked: !checked
    });

    const updateAllChecked = this.allChecked;
    const indexItem = updateAllChecked.findIndex((item) => item && item?.idobject === index);

    if (indexItem !== -1) {
      updateAllChecked[indexItem].checked = !checked;
    } else {
      updateAllChecked.push({
        idobject: index,
        type: 'vestibular',
        checked: !checked
      });
    }

    this.allChecked = updateAllChecked;
  }

  setChecked(item: Record<string, any>, isChecked: boolean) {
    if (isChecked) return;

    this.setCheckbox('vestibular', false, item.fileID);
  }

  sortListTopic(topic: Record<string, any>) {
    const newTopic = topic;

    if (newTopic.questions && newTopic.questions.length) {
      const noHaveQuestions = newTopic.questions.every((topicItem: Record<string, any>) => topicItem.questions || !topic.questions.length);

      if (noHaveQuestions) {
        newTopic.questions = this.sortTopic(newTopic.questions);
      }
    } else {
      newTopic.questions.forEach((question: Record<string, any>) => {
        this.sortListTopic(question);
      });
    }

    return newTopic;
  }

  sortTopic(listQuestions: Array<Record<string, any>>) {
    const sortQuestions = listQuestions.sort((a: Record<string, any>, b: Record<string, any>) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });

    return sortQuestions;
  }
}
