


















































import {
  Component, Vue, Watch, Mixins 
} from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ListAccordionLoading from '@/components/ListAccordionLoading/index.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';

import ExamsService from '@/services/Exams/ExamsService';
import HistoricService from '@/services/Historic/historicService';

import AccordionExam from '../../components/AccordionExam/index.vue';
import ListFiles from '../../components/ListFiles/index.vue';
import Sidebar from '../../components/ExamSidebar/index.vue';

import SetSidebar from '@/mixins/SetSidebar';
import Permission from '@/mixins/permission';

import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_ID_BOOK_QUESTION_FOR_PERMISSION } from '@/constant/ListIdsBookQuestion';

import { TypeMaterial } from '@/services/Historic/historicInterface';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    AccordionExam,
    ListFiles,
    Footer,
    FeedbackUser,
    ListAccordionLoading,
    ContainerFluid
  }
})
export default class Exam extends Mixins(SetSidebar, Permission) {
  private isLoadingExam = true;
  private isLoadingBookExam = true;
  private cover: string | null = null;
  private colorBook: string | null = null;
  private title: string | null = null;

  private newBook: Array<Record<string, any>> = [];
  private allChecked: Array<Record<string, any>> = [];

  private ExamsService = new ExamsService();
  private historicService = new HistoricService();

  async created() {
    this.onMobileChange();
    this.setBreadCrumbs();
    this.setSideBook();

    await this.setBookExam();

    await this.loadDataExams();
  }

  beforeDestroy() {
    this.$store.commit('setSidebar', false);
  }

  get hasPermission() {
    if (!this.idExam) return false;

    return this.can(LIST_ID_BOOK_QUESTION_FOR_PERMISSION[Number(this.idExam)]);
  }

  get permissionDownloadMaterials() {
    return this.can(LIST_PERMISSION.MATERIALS_DOWNLOAD);
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get idExam() {
    return this.$route.params.idExam;
  }

  get setGuideExam() {
    const guideExam = this.$store.getters.examsBook.filter(
      (bookquestion: Record<string, any>) => bookquestion.idQuestion === Number(this.idExam)
    );

    return guideExam[0]?.files;
  }

  async loadDataExams() {
    await this.getExam();
    await this.setAllChecked();
  }

  async setBookExam() {
    try {
      this.isLoadingBookExam = true;

      const exams = await this.ExamsService.getExams();

      const questionID = exams.find(
        (question: Record<string, any>) => String(question.idQuestion) === this.idExam
      );

      this.cover = questionID?.cover;
      this.colorBook = questionID?.color;
      this.title = questionID?.title;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o caderno do vestibular.',
        status: 'error'
      });
    } finally {
      this.isLoadingBookExam = false;
    }
  }

  async getExam() {
    try {
      this.isLoadingExam = true;

      const exams = await this.ExamsService.getExam(this.idExam);

      this.newBook = exams;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o vestibular.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoadingExam = false;
    }
  }

  async setAllChecked() {
    const id = this.setGuideExam;
    let response = [];

    if (!id[0]?.fileID) response = [];
    else response = await this.getHistoric('vestibular', [id[0]?.fileID]);

    this.allChecked.push(...response);
  }

  async getHistoric(type: TypeMaterial, listIds: Array<number>) {
    try {
      if (!listIds.length) return [];

      const response = await this.historicService.getHistoric({ type, listIds });

      return response || [];
    } catch (error) {
      return [];
    }
  }

  async setCheckbox(name: string, checked: boolean, index: number) {
    await this.historicService.saveHistoric({
      idobject: index,
      type: 'vestibular',
      checked: !checked
    });

    const updateAllChecked = this.allChecked;
    const indexItem = updateAllChecked.findIndex((item) => item && item?.idobject === index);

    if (indexItem !== -1) {
      updateAllChecked[indexItem].checked = !checked;
    } else {
      updateAllChecked.push({
        idobject: index,
        type: 'vestibular',
        checked: !checked
      });
    }

    this.allChecked = updateAllChecked;
  }

  setChecked(item: Record<string, any>, isChecked: boolean) {
    if (isChecked) return;

    this.setCheckbox('vestibular', false, item.fileID);
  }

  @Watch('isMobile')
  onMobileChange() {
    this.$store.commit('setSidebar', !this.isMobile);
  }

  @Watch('isLoadingBookExam')
  setSideBook() {
    const ComponentClass = Vue.extend(Sidebar);

    const instance = new ComponentClass({
      propsData: {
        cover: this.cover,
        colorBook: this.colorBook,
        title: this.title
      }
    });

    this.setSidebar(instance);
  }

  setBreadCrumbs() {
    const { book } = this.$route.params;

    this.$breadcrumb.set([
      { title: 'Vestibulares', to: '/vestibular' },
      { title: book, to: null }
    ]);
  }
}
